import './IncidentCancellationContextMenuPart.scss'

import IEntityIncident from '../../interfaces/entityIncident/IEntityIncident'
import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import IApplicationSettings from '../../interfaces/applicationSettings/IApplicationSettings'
import StandardActionButton from '../standardButton/standardActionButton/StandardActionButton'


type ComponentProps = {
  customerID:string
  entityIncidents:IEntityIncident[]
  applicationSettings: null|undefined|IApplicationSettings
  handleIncidentCancellation: (reason:string, reasonRemark:string) => void
}

const IncidentCancellationContextMenuPart = (props:ComponentProps) => {
  
  const {instance, inProgress, accounts } = useMsal()

  let user = instance.getActiveAccount()

  let allowIncidentCancellationCorrect:boolean = false
  let allowIncidentCancellationIncorrect:boolean = false 

  if (props.applicationSettings)
  {
    if (props.applicationSettings?.AllowIncidentCancellationCorrect) 
      allowIncidentCancellationCorrect = (Number.parseInt(props.applicationSettings.AllowIncidentCancellationCorrect) != 0)
    if (props.applicationSettings?.AllowIncidentCancellationIncorrect) 
      allowIncidentCancellationIncorrect = (Number.parseInt(props.applicationSettings.AllowIncidentCancellationIncorrect) != 0)
  }

  const [correctReasonRemark, setCorrectReasonRemark] = useState('')
  const [incorrectReasonRemark, setIncorrectReasonRemark] = useState('')

  return (
    <> 
      <div className='incident-cancellation-content'>
      {
        allowIncidentCancellationCorrect &&
        <>
          <div className='menu-item reason'>
              Sense har ret i den konkrete vurdering, men jeg ønsker at håndtere den på anden vis.
          </div>
          <div className='menu-item reason-remark'>
            <input
              className='reason-remark-input'
              spellCheck='false'
              placeholder='Uddyb årsagen'
              onChange={(event: React.FormEvent<HTMLInputElement>) => setCorrectReasonRemark(event.currentTarget.value) }>
            </input>
            <div className='reason-remark-button-container'>
              <StandardActionButton key='incident-cancellation-correct-button' status={correctReasonRemark ? 'enabled' : 'disabled'} id='incident-cancellation-correct-button' title='OK' onClick={() => {props.handleIncidentCancellation('CorrectSystemAssessmentExternalHandling', correctReasonRemark)}} />
            </div>        
          </div>
        </>
      }
      {
        allowIncidentCancellationIncorrect &&
        <>
          <div className='menu-item reason'>
              Sense tager fejl i den konkrete vurdering. Den skal ikke håndteres.
          </div>

          <div className='menu-item reason-remark'>
            <input
              className='reason-remark-input'
              spellCheck='false'
              placeholder='Giv bud på fejlen'
              onChange={(event: React.FormEvent<HTMLInputElement>) => setIncorrectReasonRemark(event.currentTarget.value) }>
            </input>
            <div className='reason-remark-button-container'>
              <StandardActionButton key='incident-cancellation-incorrect-button' status={incorrectReasonRemark ? 'enabled' : 'disabled'} id='incident-cancellation-incorrect-button' title='OK' onClick={() => {props.handleIncidentCancellation('IncorrectSystemAssessment', incorrectReasonRemark)}} />
            </div>        
          </div>
        </>
      }
      </div>        
    </>
  )
}

export default IncidentCancellationContextMenuPart