import { EventHandler } from '@azure/msal-browser/dist/event/EventHandler'
import logoImage from './images/logo.png'
import logoNoTextImage from './images/logoNoText.png'
import './Logo.scss'
import IncidentCollectionRequestType from '../../../common/types/IncidentCollectionRequestType'

type ComponentProps = {
  incidentCollectionRequest: IncidentCollectionRequestType|undefined
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleIncidentOverviewRequest: () => void
}

const handleClick = (props:ComponentProps) => {
  props.handleIncidentOverviewRequest()
  if (props.incidentCollectionRequest)
  {
    props.handleIncidentCollectionChangeRequest(
    {
      Title: props.incidentCollectionRequest.Title,
      Area: props.incidentCollectionRequest.Area, 
      Value: props.incidentCollectionRequest.Value, 
      Timestamp: new Date()
    })
  }
}

const Logo = (props:ComponentProps) => {

  return (
    <div id='logo' onClick={() => handleClick(props)}>
      <img id='logo-image' title='Opdater' alt='' src={logoImage} />
      <img id='logo-no-text-image' title='Opdater' alt='' src={logoNoTextImage} />
    </div>
  )
}

export default Logo