import IEntityIncident from '../../../../common/interfaces/entityIncident/IEntityIncident'
import './IncidentListColumnHeaders.scss'
import Form from 'react-bootstrap/Form'
import IncidentListContextMenu from './incidentListContextMenu/incidentListContextMenu'
import IncidentCollectionSortDetailsType from '../../../../common/types/IncidentCollectionSortType'
import IApplicationSettings from '../../../../common/interfaces/applicationSettings/IApplicationSettings'
import IncidentCollectionRequestType from '../../../../common/types/IncidentCollectionRequestType'



type ComponentProps = {
  customerID: string
  incidentSelection?: IEntityIncident[]
  handleIncidentSelectionChange: () => void
  handleEntityIncidentsToRemoveChange:(entityIncidents:IEntityIncident[]) => void
  incidentCollectionSortDetails: IncidentCollectionSortDetailsType
  incidentCollectionRequest: IncidentCollectionRequestType
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleRefreshApplication: () => void
  handleIncidentCollectionSortDetailsChange: (sortDetails:IncidentCollectionSortDetailsType) => void
  handleIncidentOverviewRequest: () => void
  applicationSettings: null|undefined|IApplicationSettings
}

const IncidentListColumnHeaders = (props:ComponentProps) => {

  const handleSelectionChangeByUser = (event: React.ChangeEvent<HTMLInputElement>) => {

    const elements:any = document.getElementsByClassName('form-check-input')

    Array.prototype.map.call(
      elements, function(element:Element) {
        if (element instanceof HTMLInputElement) (element as HTMLInputElement).checked = event.target.checked
      })
      props.handleIncidentSelectionChange()
  }


  const handleLocalSortChange = (index:number) => { 
    if (!props.incidentCollectionSortDetails) return
    var sortDetails:IncidentCollectionSortDetailsType = props.incidentCollectionSortDetails
    sortDetails.sortAcsending[index] = !props.incidentCollectionSortDetails.sortAcsending[index]
    sortDetails.activeIndex = index
    props.handleIncidentCollectionSortDetailsChange(sortDetails)
  }

  const columnHeaderSortCellClassNames = (sortDetails:IncidentCollectionSortDetailsType, index:number):string => {

    return (
      'column-header-cell text-wrapped sort-column-header-cell' + 
      (
        sortDetails.activeIndex === index ? ' sort-column-header-cell-active' : ' sort-column-header-cell-inactive'
      ) + 
      (
        sortDetails.activeIndex === index 
          ?          
            sortDetails.sortAcsending[index] ? ' sort-column-header-cell-ascending' : ' sort-column-header-cell-descending' 
          : 
            ''
      )
    )
  }

  return (
    <div id='incident-list-column-headers'>
      <div id='incident-list-column-headers-context-menu-wrapper' className='column-header-cell'>
        {
          props.incidentSelection && 
          <IncidentListContextMenu 
            customerID={props.customerID} 
            entityIncidents={props.incidentSelection} 
            incidentCollectionRequest={props.incidentCollectionRequest} 
            handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest}
            handleRefreshApplication={props.handleRefreshApplication}
            handleEntityIncidentsToRemoveChange={props.handleEntityIncidentsToRemoveChange}
            handleIncidentOverviewRequest={props.handleIncidentOverviewRequest}
            applicationSettings={props.applicationSettings}
            />
        }
      </div>
      <Form.Check type='checkbox' id='incident-list-column-headers-selected-checkbox' onChange={handleSelectionChangeByUser}></Form.Check>
      <div id='incident-list-column-headers-entity-title' className={columnHeaderSortCellClassNames(props.incidentCollectionSortDetails, 0)} onClick={() => handleLocalSortChange(0)}>Titel</div>
      <div id='incident-list-column-headers-entity-friendly-container' className={columnHeaderSortCellClassNames(props.incidentCollectionSortDetails, 1)} onClick={() => handleLocalSortChange(1)}>Placeret i</div>
      <div id='incident-list-column-headers-entity-default-timestamp' className={columnHeaderSortCellClassNames(props.incidentCollectionSortDetails, 2)} onClick={() => handleLocalSortChange(2)}>Dato</div>
      <div id='incident-list-column-headers-preview-match-result' className='column-header-cell text-wrapped'>Match</div>
    </div>
  )
}

export default IncidentListColumnHeaders