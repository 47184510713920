import './IncidentItemContextMenu.scss'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import contextMenuImage from './../../../../../common/images/context-menu/contextMenuDark.png'
import IEntityIncident from '../../../../../common/interfaces/entityIncident/IEntityIncident'
import copyToClipboard from 'copy-to-clipboard'
import CreateIncidentAction from '../../../../../common/services/action/ActionService'
import CreateIncidentCancellation from '../../../../../common/services/incidentCancellation/IncidentCancellationService'
import { useMsal } from '@azure/msal-react'
import IIncidentMatchResult from '../../../../../common/interfaces/entityIncident/IIncidentMatchResult'
import IIncidentMatchResultPart from '../../../../../common/interfaces/entityIncident/IIncidentMatchResultPart'
import IIncidentMatch from '../../../../../common/interfaces/entityIncident/IIncidentMatch'
import { useEffect, useState } from 'react'
import IncidentCancellationContextMenuPart from '../../../../../common/components/incidentCancellation/IncidentCancellationContextMenuPart'
import IApplicationSettings from '../../../../../common/interfaces/applicationSettings/IApplicationSettings'
import IncidentCollectionRequestType from '../../../../../common/types/IncidentCollectionRequestType'


type ComponentProps = {
  customerID:string
  entityIncident:IEntityIncident
  incidentCollectionRequest: IncidentCollectionRequestType
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleRefreshApplication: () => void
  handleIncidentOverviewRequest: () => void
  handleEntityIncidentsToRemoveChange: (entityIncidents:IEntityIncident[]) => void
  applicationSettings: null|undefined|IApplicationSettings
}

const IncidentItemContextMenu = (props:ComponentProps) => {

  const [incidentCancellationToggleVisible, setIncidentCancellationToggleVisible] = useState<boolean>(false)

  const { instance } = useMsal()

  let user = instance.getActiveAccount()

  const entityIncident = props.entityIncident
  const entityIncidentID:string = (entityIncident.entity.entityType + '-' + entityIncident.entity.entityID + '-' + entityIncident.incident.name)
  const entityIncidents:IEntityIncident[] = []
  entityIncidents.push(entityIncident)
    
  let allowIncidentCancellationCorrect:boolean = false
  let allowIncidentCancellationIncorrect:boolean = false 
  
  if (props.applicationSettings)
  {
    if (props.applicationSettings?.AllowIncidentCancellationCorrect) 
      allowIncidentCancellationCorrect = (Number.parseInt(props.applicationSettings.AllowIncidentCancellationCorrect) != 0)
    if (props.applicationSettings?.AllowIncidentCancellationIncorrect) 
      allowIncidentCancellationIncorrect = (Number.parseInt(props.applicationSettings.AllowIncidentCancellationIncorrect) != 0)
  }

  const renderContextMenu:any = (entityIncident:IEntityIncident) => (
    <Popover id={'incident-item-context-menu-popover-id-' + entityIncidentID}>
      <Popover.Header className='incident-item-context-menu-popover-header'>Handlinger for det valgte emne</Popover.Header>
        <Popover.Body className='incident-item-context-menu-popover-body' >
          <div className='incident-item-context-menu-popover-body-menu-item' onClick={copyUniqueMatchesToClipboard}>Kopier unikke match-værdier</div>
          <div className='incident-item-context-menu-popover-body-menu-item' onClick={handleActionDelete}>Slet permanent</div>
          {
            (allowIncidentCancellationCorrect || allowIncidentCancellationIncorrect) &&
            <>          
            <div className='incident-item-context-menu-popover-body-menu-item' onClick={() => {setIncidentCancellationToggleVisible((previous) => !previous)}}>Annullering af hændelsen...</div>
            <div className='incident-cancellation-context-menu-part-container'>
              {
                incidentCancellationToggleVisible && 
                <IncidentCancellationContextMenuPart 
                  customerID={props.customerID} 
                  entityIncidents={entityIncidents} 
                  applicationSettings={props.applicationSettings} 
                  handleIncidentCancellation={handleIncidentCancellation}/>
              }
            </div>
            </>
          }
          <div className='incident-item-context-menu-popover-body-menu-item' onClick={copyEntityAccessLinkToClipboard}>Kopiér link</div>
          <div className='incident-item-context-menu-popover-body-menu-item' onClick={copyEntityFriendlyContainerAccessLinkToClipboard}>Kopiér link til placeringen</div>
          {/* <div className='incident-item-context-menu-popover-body-menu-item'>Sæt mærkat - Whistleblower-relateres</div> */}
        </Popover.Body>
    </Popover>
  )
  
  const copyUniqueMatchesToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    const matchList = [] as string[];
    if (entityIncident.incident.matchResult) {
      entityIncident.incident.matchResult.map((matchResult:IIncidentMatchResult) => (
        matchResult.parts.map((matchResultPart:IIncidentMatchResultPart) => (
          matchResultPart.matches.map((match:IIncidentMatch) => (
             matchList.push(match.match)
          ))    
        ))
      ))
    } 
    let matchListUnique = [...new Set(matchList)]
    const resultStringToCopy = matchListUnique.join('\r\n')
    copyToClipboard(resultStringToCopy)
    hidePopover('incident-item-context-menu-popover-id-' + entityIncidentID)
  }

  const handleActionDelete = (event: React.MouseEvent<HTMLElement>) => {
    const entityIncidents:IEntityIncident[] = []
    entityIncidents.push(props.entityIncident)
    CreateIncidentAction(instance, props.customerID, 'Delete', entityIncidents)
    hidePopover('incident-item-context-menu-popover-id-' + entityIncidentID)
    props.handleEntityIncidentsToRemoveChange(entityIncidents)
  }
  
  const handleIncidentCancellation = (reason:string, reasonRemark:string) => {
    const entityIncidents:IEntityIncident[] = []
    entityIncidents.push(props.entityIncident)
    if (user) 
    {
      CreateIncidentCancellation(instance, props.customerID, user.username, entityIncidents, reason, reasonRemark)
      .then(() => {
        props.handleIncidentOverviewRequest()
        props.handleIncidentCollectionChangeRequest(
        {
          Title: props.incidentCollectionRequest.Title,
          Area: props.incidentCollectionRequest.Area, 
          Value: props.incidentCollectionRequest.Value, 
          Timestamp: new Date()
        })
      })
    }
    hidePopover('incident-item-context-menu-popover-id-' + entityIncidentID)
  }

  const copyEntityAccessLinkToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    if (props.entityIncident.incident.entityAccessLink) {
      copyToClipboard(props.entityIncident.incident.entityAccessLink)
      hidePopover('incident-item-context-menu-popover-id-' + entityIncidentID)
    }
  }

  const copyEntityFriendlyContainerAccessLinkToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    if (props.entityIncident.incident.entityFriendlyContainerAccessLink) {
      copyToClipboard(props.entityIncident.incident.entityFriendlyContainerAccessLink)
      hidePopover('incident-item-context-menu-popover-id-' + entityIncidentID)
    }
  }

  const hidePopover = (popoverID: string) => {
    const popover = document.getElementById(popoverID)
    popover?.style.setProperty('display', 'none')
  }

  return (
    <> 
      <OverlayTrigger trigger={['click']} rootClose={true} placement='auto' overlay={renderContextMenu(props.entityIncident) }>
        <div className='incident-item-context-menu'>
          <img className='incident-item-context-menu-image' src={contextMenuImage} alt=''></img>
        </div>        
      </OverlayTrigger>
    </>
  )
}

export default IncidentItemContextMenu