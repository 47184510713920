import './ManualHandlingIncidentTypes.scss'
import OverviewSection from '../../../../common/components/overviewSection/OverviewSection'
import ManualHandlingIncidentType from './manualHandlingIncidentType/ManualHandlingIncidentType'
import { IncidentCollectionRequestType } from './../../../../common/types/IncidentCollectionRequestType'
import IIncidentOverview from './../../../../common/interfaces/incidentOverview/IIncidentOverview'
import IIncidentOverviewIncidentType from '../../../../common/interfaces/incidentOverview/IIncidentOverviewIncidentType'
import { Fragment, useEffect, useState } from 'react'
import React from 'react'
import IIncidentOverviewSeverityLevelIncident from '../../../../common/interfaces/incidentOverview/IIncidentOverviewSeverityLevelIncident'

type ComponentProps = {
  domain:string
  incidentOverview:null|undefined|IIncidentOverview
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
}

const ManualHandlingIncidentTypes = (props: ComponentProps) => {

  let countSeverityLevels:IIncidentOverviewSeverityLevelIncident | undefined = props.incidentOverview?.manualHandling.countSeverityLevels
  const countSeverityLevel1 = countSeverityLevels?.severityLevel1 ?? 0
  const countSeverityLevel2 = countSeverityLevels?.severityLevel2 ?? 0
  const countSeverityLevel3 = countSeverityLevels?.severityLevel3 ?? 0
  const total = (countSeverityLevel1 + countSeverityLevel2 + countSeverityLevel3)
  let percentageLevel2 = 0
  let percentageLevel3 = 0
  let severityLevelWidthStyle = 'auto auto auto'

  if (total !== 0) {
    percentageLevel2 = ((countSeverityLevel2 / total) * 100)
    percentageLevel3 = ((countSeverityLevel3 / total) * 100)
    severityLevelWidthStyle = `${Math.floor(percentageLevel3)}% ${Math.floor(percentageLevel2)}% auto`
  }

  let incidentSum = 0

  if (props.incidentOverview?.manualHandling.incidentTypes !== undefined) {
    const incidentTypes:IIncidentOverviewIncidentType[] = props.incidentOverview?.manualHandling.incidentTypes
    for (const incidentType of (incidentTypes as IIncidentOverviewIncidentType[])) {
      incidentSum = incidentSum + incidentType.incidentCount
    } 
  }

  return (
    <OverviewSection contextID='manualHandlingIncidents' incidentOverview={props.incidentOverview} headerTitle='Hændelser' headerStatus={incidentSum ? (incidentSum + ' stk.') : ''}>
      <div id='manual-handling-incidents'>

        <div id='manual-handling-incident-types'>
          {
            props.incidentOverview?.manualHandling.incidentTypes.map((incidentType:IIncidentOverviewIncidentType, indexIncidentType:number) => (
              <ManualHandlingIncidentType key={incidentType.name} domain={props.domain} id={incidentType.name} severityLevel={incidentType.severityLevel} title={incidentType.title} incidentCount={incidentType.incidentCount} incidentSum={incidentSum} handleIncidentCollectionRequest={props.handleIncidentCollectionChangeRequest}></ManualHandlingIncidentType>
            ))
          }
        </div>
        

        <div id='manual-handling-incident-severityLevels' style={{gridTemplateColumns: severityLevelWidthStyle}}>
          {
            [1, 2, 3].map((severityLevel) => {
              return (
                <React.Fragment key={severityLevel}>
                  <div 
                    id={'manual-handling-incident-severityLevel' + severityLevel.toString()} 
                    className='manual-handling-incident-severityLevel'
                    onClick={() => {
                      props.handleIncidentCollectionChangeRequest(
                        {
                          Title: ('Alvorlighedsniveau ' + severityLevel.toString()), 
                          Area: 'SeverityLevelRequest', 
                          Value: {NumberValue: severityLevel}, 
                          Timestamp: new Date()
                        }
                      )
                    }}>
                      {                      
                        severityLevel === 1 && (countSeverityLevels?.severityLevel1 && countSeverityLevels.severityLevel1 > 5) ? countSeverityLevels.severityLevel1 :
                          severityLevel === 2 && (countSeverityLevels?.severityLevel2 && countSeverityLevels.severityLevel2 > 5) ? countSeverityLevels.severityLevel2 :
                            severityLevel === 3 && (countSeverityLevels?.severityLevel3 && countSeverityLevels.severityLevel3 > 5) ? countSeverityLevels.severityLevel3 :
                              ''
                      }
                    </div> 
                  </React.Fragment>
                )
              })
          }
        </div>
      </div>
    </OverviewSection>
  )
}

export default ManualHandlingIncidentTypes