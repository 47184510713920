import './ManualHandlingShortcuts.scss'
import OverviewSection from '../../../../common/components/overviewSection/OverviewSection'
import StandardSearchButton from './../../../../common/components/standardButton/standardSearchButton/StandardSearchButton'
import { IncidentCollectionRequestType } from './../../../../common/types/IncidentCollectionRequestType'
import { useEffect, useState } from 'react'
import IIncidentOverview from '../../../../common/interfaces/incidentOverview/IIncidentOverview'

type ComponentProps = {
  incidentOverview:null|undefined|IIncidentOverview
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
}



const ManualHandlingShortcuts = (props:ComponentProps) => {

  const searchPhraseMatchResultAssessmentDataLists = 'OccurrencesHaveRegularity'

  return (
    <OverviewSection contextID='manualHandlingShortcuts' incidentOverview={props.incidentOverview} headerTitle='Genveje'>
      <div id='manual-handling-shortcuts'>
        <StandardSearchButton key='incident-scope-within-deadline-button' status='enabled' id='incident-scope-within-deadline-button' title='Indenfor frist' tooltip='Alle indenfor fristen af 30 dage.' onClick={(id, title) => {props.handleIncidentCollectionChangeRequest({Title: title, Area: 'PredefinedRequest', Value: {StringValue: 'WithinDeadline'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-near-deadline-button' status='enabled' id='incident-scope-near-deadline-button' title='Tæt på frist' tooltip='30 dages frist, med 7 dage tilbage.' onClick={(id, title) => {props.handleIncidentCollectionChangeRequest({Title: title, Area: 'PredefinedRequest', Value: {StringValue: 'NearDeadline'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-crossed-deadline-button' status='enabled' id='incident-scope-crossed-deadline-button' title='Over-skredet frist' tooltip='30 dages frist, er overskredet.' onClick={(id, title) => {props.handleIncidentCollectionChangeRequest({Title: title, Area: 'PredefinedRequest', Value: {StringValue: 'CrossedDeadline'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-clear-command-delete-button' status='enabled' id='incident-scope-clear-command-delete-button' title='Allerede slettet' tooltip='Hændelser for emner placeret i slette-containere (soft deleted).' onClick={(id, title) => {props.handleIncidentCollectionChangeRequest({Title: title, Area: 'ClearCommandRequest', Value: {StringValue: 'DeleteDiscarded'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-important-button' status='enabled' id='incident-scope-important-button' title='De kritiske' tooltip='Hændelser med højt kritisk niveau.' onClick={(title) => {props.handleIncidentCollectionChangeRequest({Title: title, Area: 'SeverityLevelRequest', Value: {NumberValue: 3}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-five-minutes-button' status='enabled' id='incident-scope-five-minutes-button' title='Har fem min.' tooltip='Hændelser der tilsammen kan håndteres indenfor en kort periode.' onClick={(id, title) => {props.handleIncidentCollectionChangeRequest({Title: title, Area: 'PredefinedRequest', Value: {StringValue: 'DoneInFiveMinutes'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-scope-fast-button' status='enabled' id='incident-scope-fast-button' title='De hurtige'  tooltip='Hændelser der kan håndteres hurtigt.' onClick={(id, title) => {props.handleIncidentCollectionChangeRequest({Title: title, Area: 'PredefinedRequest', Value: {StringValue: 'Fast'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-new-button' status='enabled' id='incident-scope-new-button' title='De nye' tooltip='Hændelser opdatet for nyligt.' onClick={(id, title) => {props.handleIncidentCollectionChangeRequest({Title: title, Area: 'PredefinedRequest', Value: {StringValue: 'New'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-data-lists-button' status='enabled' id='incident-scope-data-lists-button' title='Datalister' tooltip='Hændelser på lister af data.' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Match-vurdering', Area: 'MatchResultAssessmentRequest', Value: {StringValue: searchPhraseMatchResultAssessmentDataLists}, Timestamp: new Date()})}}/>
      </div>
    </OverviewSection>
  )
}

export default ManualHandlingShortcuts