import './IncidentOverview.scss'

import AutomaticHandlingRecommendations from './automaticHandling/AutomaticHandlingRecommendations'

import ManualHandlingIncidentTypes from './manualHandling/manualHandlingIncidentTypes/ManualHandlingIncidentTypes'
import ManualHandlingShortcuts from './manualHandling/manualHandlingShortcuts/ManualHandlingShortcuts'
import ManualHandlingTextSearch from './manualHandling/manualHandlingTextSearch/ManualHandlingTextSearch'
import IncidentCollectionRequestType from './../../common/types/IncidentCollectionRequestType'
import IncidentActionRequestType from './../../common/types/IncidentActionRequestType'
import IIncidentOverview from '../../common/interfaces/incidentOverview/IIncidentOverview'
import { useMsal } from '@azure/msal-react'
import { useEffect} from 'react'
import { AccountInfo, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { msalMainApiRequest } from '../../configuration'
import StatusDetailedAnalysisComparisonBars from './status/statusDetailedAnalysis/statusDetailedAnalisysComparisonBars/StatusDetailedAnalysisComparisonBars'
import StatusDetailedAnalysisComparisonPeriodGraph from './status/statusDetailedAnalysis/statusDetailedAnalisysComparisonPeriodGraph/StatusDetailedAnalysisComparisonPeriodGraph'
import IEntityIncident from '../../common/interfaces/entityIncident/IEntityIncident'
import DomainVideoChannel from './domainVideoChannel/DomainVideoChannel'


type ComponentProps = {
  customerID: string
  domain: string
  incidentOverview: null|undefined|IIncidentOverview
  settingsMinimizeOverviewWhenRequestingCollection: boolean
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleIncidentActionChangeRequest: (request:IncidentActionRequestType) => void
  handleEntityIncidentsToRemoveChange:(entityIncidents:IEntityIncident[]) => void
  handleIncidentOverviewRequest: () => void
  incidentCollectionCloseRequest: string
}

const IncidentOverview = (props:ComponentProps) => {

  return (
    <div id='overview-sections'>

      <ManualHandlingIncidentTypes 
        domain={props.domain}
        incidentOverview={props.incidentOverview}
        handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest} />

      <ManualHandlingTextSearch 
        incidentOverview={props.incidentOverview}
        handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest} 
        incidentCollectionCloseRequest={props.incidentCollectionCloseRequest} />

      <ManualHandlingShortcuts 
        incidentOverview={props.incidentOverview}
        handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest} />

      {props.incidentOverview?.status.detailedAnalysis.userIncidentScores && 
        <StatusDetailedAnalysisComparisonBars 
        incidentOverview={props.incidentOverview} />
      }
      
      <AutomaticHandlingRecommendations
        customerID={props.customerID}
        domain={props.domain}
        incidentOverview={props.incidentOverview}
        handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest} 
        handleIncidentActionChangeRequest={props.handleIncidentActionChangeRequest} 
        handleEntityIncidentsToRemoveChange={props.handleEntityIncidentsToRemoveChange} />

      {props.settingsMinimizeOverviewWhenRequestingCollection && 
        <DomainVideoChannel 
        incidentOverview={props.incidentOverview}
          domain={props.domain} />        
      }
        
    </div>
  )
}

export default IncidentOverview