import IIncidentOverview from '../../interfaces/incidentOverview/IIncidentOverview'
import './OverviewSection.scss'
import React, { ReactNode, useEffect, useState } from "react"

type ComponentProps = {
  children: ReactNode
  contextID: string
  incidentOverview:null|undefined|IIncidentOverview
  headerTitle: string
  headerElement?: any
  headerStatus?: string
}
 

const OverviewSection = (props: ComponentProps) => {

  const getShowContentStorage = (key:string):boolean => {
    const storageValue:string|null = localStorage.getItem(key)
    return storageValue ? storageValue === '1' ? true : false : false
  }
  
  const setShowContentStorage = (key:string, showContent:boolean) => {
    localStorage.setItem(props.contextID + '-showContent', showContent ? '1' : '0')
  }
  
  // useEffect(() => {
  //   if (props.updateIncidentOverview != '') setShowContent(false)
  // }, [props.updateIncidentOverview])

  const [showContent, setShowContent] = useState<boolean>(getShowContentStorage(props.contextID + '-showContent'))
  
  useEffect(() => {
    setShowContentStorage(props.contextID + '-showContent', showContent)  
  }, [showContent])

 
  const headerClasses:string = 'header header-normal'

  return (
    <div className='overview-section'>
      <div className={headerClasses} onClick={() => setShowContent(previousValue => !previousValue)}>
        <div className='header-title'>
          {props.headerTitle}
        </div>
          <div className='header-details' >
            {
              props.headerElement 
                ? 
                  showContent ? undefined : props.headerElement
                :
                  props.headerStatus
            }
          </div>
      </div>
      <div className={'section-content-wrapper ' + (showContent ? 'section-content-visible' : 'section-content-hidden')}>
        {props.children}
      </div>
    </div>
  )
}

export default OverviewSection