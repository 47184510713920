import { useEffect, useState } from 'react'
import './DomainSelector.scss'
import { DropdownButton, Dropdown } from 'react-bootstrap'



type ComponentProps = {
  handleDomainChangeRequest: (domain:string) => void
}

const DomainSelector = (props:ComponentProps) => {

  const [domainList, setDomainList] = useState<string[]>([])
  const [domainSelected, setDomainSelected] = useState<string>('')
  const [domainListInitialized, setDomainListInitialized] = useState<boolean>(false)
  
  useEffect(() => {
    setDomainList(['Sagsbehandler+'])
    setDomainSelected('Sagsbehandler+')
    setDomainListInitialized(true)

  }, [])

  const handleSelect = (selectedItem:any) => {
    if (!selectedItem || !domainListInitialized) return
    const selectedDomainTyped:string = selectedItem
    setDomainSelected(selectedItem)
    props.handleDomainChangeRequest(selectedDomainTyped)
  }  
  

  return (
    <div id='domain-selector'>
      {
        domainList[0] &&
        <DropdownButton id='domain-selection-drop-down-button' variant='secondary' onSelect={(selectedItem) => handleSelect(selectedItem)} title={domainSelected}>
        {
          domainList.map((item:any, domainIndex:any) => (
            (
              <Dropdown.Item key={domainIndex} eventKey={item}>
                {item}
              </Dropdown.Item>
            )
          ))
        }
        </DropdownButton>
      }
    </div>
  )
}



export default DomainSelector
