import { Form, OverlayTrigger, Popover } from 'react-bootstrap'
import settingsCommandImage from './images/settings.png'
import './SettingsCommand.scss'
import { APPLICATION_VERSION } from '../../../version'

type ComponentProps = {
  handleSettingsMinimizeOverviewWhenRequestingCollectionChange: (value:boolean) => void
  settingsMinimizeOverviewWhenRequestingCollection: boolean
  handleSettingsAllowDomainVideoChannelChange: (value:boolean) => void
  settingsAllowDomainVideoChannel: boolean
}

const SettingsCommand = (props:ComponentProps) => {

  const handleSettingsMinimizeOverviewWhenRequestingCollectionChangeLocal = (event:any) => {
    props.handleSettingsMinimizeOverviewWhenRequestingCollectionChange(event.target.checked)
    hidePopover('settings-context-menu-popover')
  }

  // const handleSettingsAllowDomainVideoChannelChangeLocal = (event:any) => {
  //   props.handleSettingsAllowDomainVideoChannelChange(event.target.checked)
  //   hidePopover('settings-context-menu-popover')
  // }

  const renderContextMenu:any = () => (
    <Popover id={'settings-context-menu-popover'}>
      <Popover.Header className='settings-context-menu-popover-header'>Indstillinger<br></br>Programversion: { APPLICATION_VERSION }</Popover.Header>
      <Popover.Body className='settings-context-menu-popover-body'>
        
        <div className='settings-context-menu-popover-body-menu-item'>
          <Form.Check 
            id = 'settings-minimize-incident-overview-checkbox' 
            type = 'switch'
            defaultChecked = {props.settingsMinimizeOverviewWhenRequestingCollection}
            label = {'Minimér venstre panel automatisk'}
            onChange = {handleSettingsMinimizeOverviewWhenRequestingCollectionChangeLocal} 
          />
        </div>

        {/*
        <div className='settings-context-menu-popover-body-menu-item'>
          <Form.Check 
            id = 'settings-allow-domain-video-channel-checkbox' 
            type = 'switch'
            defaultChecked = {props.settingsAllowDomainVideoChannel}
            label = {'Vis domæne videoer i overblikket'}
            onChange = {handleSettingsAllowDomainVideoChannelChangeLocal} 
          />
        </div>
        */}
      </Popover.Body>
    </Popover>
  )

  const hidePopover = (popoverID: string) => {
    const popover = document.getElementById(popoverID)
    popover?.style.setProperty('display', 'none')
  }

  return (
    <OverlayTrigger trigger={['click']} rootClose={true} placement='auto' overlay={renderContextMenu()}>
      <div id='settings-command'>
        <img id='settings-command-image' src={settingsCommandImage} alt=''/>
      </div>
    </OverlayTrigger>
  )
}

export default SettingsCommand
