import './Menu.scss'
import Logo from './logo/Logo'
import StatusCommand from './statusCommand/StatusCommand'
import SettingsCommand from './settingsCommand/SettingsCommand'
import CurrentIncidentCollectionTab from './currentIncidentCollectionTab/CurrentIncidentCollectionTab'
import User from './user/User'
import DomainSelector from './domainSelector/DomainSelector'
import IncidentCollectionRequestType from './../../common/types/IncidentCollectionRequestType'
import IIncidentOverview from './../../common/interfaces/incidentOverview/IIncidentOverview'
import IEntityIncident from '../../common/interfaces/entityIncident/IEntityIncident'

type ComponentProps = {
  incidentOverview:null|undefined|IIncidentOverview
  handleRefreshApplication: () => void
  handleSettingsMinimizeOverviewWhenRequestingCollectionChange: (value:boolean) => void
  settingsMinimizeOverviewWhenRequestingCollection: boolean
  handleSettingsAllowDomainVideoChannelChange: (value:boolean) => void
  handleDomainChangeRequest: (domain:string) => void
  settingsAllowDomainVideoChannel: boolean
  incidentCollectionRequest?: IncidentCollectionRequestType
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleIncidentOverviewRequest: () => void
  incidentCollection?: IEntityIncident[]
  handleIncidentCollectionClose: () => void
}

const Menu = (props:ComponentProps) => (

  <div id='menu' className={props.incidentCollection ? 'menu-no-default-dark' : 'menu-default-dark'}>
    <div id='logo-wrapper' className='gap-to-next'>
      <Logo 
        incidentCollectionRequest={props.incidentCollectionRequest} 
        handleIncidentOverviewRequest={props.handleIncidentOverviewRequest}
        handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest}
      />
    </div>
    <div id='status-command-wrapper' className='gap-to-next'>
      {props.incidentOverview?.status.detailedAnalysis.userIncidentScores && 
        <StatusCommand 
          incidentOverview={props.incidentOverview} 
        />
      }
    </div>
  
    <div id='settings-command-wrapper' className='gap-to-next'>
      <SettingsCommand 
        handleSettingsMinimizeOverviewWhenRequestingCollectionChange={props.handleSettingsMinimizeOverviewWhenRequestingCollectionChange}
        settingsMinimizeOverviewWhenRequestingCollection={props.settingsMinimizeOverviewWhenRequestingCollection}
        handleSettingsAllowDomainVideoChannelChange={props.handleSettingsAllowDomainVideoChannelChange}
        settingsAllowDomainVideoChannel={props.settingsAllowDomainVideoChannel}
      />
    </div>
    <div id='domain-selector-wrapper' className='gap-to-next'>
      <DomainSelector 
        handleDomainChangeRequest={props.handleDomainChangeRequest}
      />
    </div>
    <div id='current-incident-collection-tab-wrapper' className='gap-to-next'>
      {
        props.incidentCollectionRequest &&
        <CurrentIncidentCollectionTab 
          incidentCollectionRequest={props.incidentCollectionRequest} 
          incidentCollection={props.incidentCollection} 
          handleIncidentCollectionClose={props.handleIncidentCollectionClose}/>
      }
    </div>
    <div id='user-wrapper'>
      <User />
    </div>
  </div>
)

export default Menu