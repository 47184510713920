import { IPublicClientApplication } from "@azure/msal-browser"
import { reactMainApiBaseUrl, customerKey, msalMainApiRequest } from "../../../configuration"
import GetAccessToken from '../../functions/security/AccessTokenProvider'
import axios, { AxiosRequestConfig } from "axios"
import ICustomer from "../../interfaces/customer/ICustomer"
import IApplicationSettings from "../../interfaces/applicationSettings/IApplicationSettings"

export default async function GetApplicationSettings(publicClientApplication:IPublicClientApplication, customerID:string):Promise<IApplicationSettings> {

  const accessToken = await GetAccessToken(publicClientApplication, msalMainApiRequest)
  const parameters = await createRequestParameters(customerID)
  const requestConfiguration:AxiosRequestConfig = {
    url: (reactMainApiBaseUrl(customerID) + '/application-settings'),
    method: 'GET',
    params: parameters,
    headers: { Authorization: ('Bearer ' + accessToken)},
  }

  return axios(requestConfiguration)
    .then(result => {
      return result.data
    })
    .catch(event => {
      Error("Could not retrieve application settings from Sense React Api.\n\n" + event)
    })
}

const createRequestParameters = async (customerID:string) => {

  const parameters = new URLSearchParams()

  parameters.append('code', customerKey)
  parameters.append('id', customerID)
  
  return parameters
}