import OverviewSection from '../../../common/components/overviewSection/OverviewSection';
import IIncidentOverview from '../../../common/interfaces/incidentOverview/IIncidentOverview';
import './DomainVideoChannel.scss'
import { useEffect, useState } from 'react'

type ComponentProps = {
  incidentOverview:null|undefined|IIncidentOverview
  domain: string
}

const DomainVideoChannel = (props:ComponentProps) => {

  return (
    <OverviewSection contextID='manualHandlingShortcuts' incidentOverview={props.incidentOverview} headerTitle='Videoer for domænet'>
      <div id='domain-video-channel'>
        <iframe 
            id = 'domain-video-channel-iframe'
            src = "https://www.youtube.com/embed/videoseries?list=PLz02sLlFK5EZVS0M3dLoOujaOa1A71280" title="Sense Youtube-kanal" frameBorder="0" allow="clipboard-write; encrypted-media; picture-in-picture; web-share" 
            allowFullScreen>
          </iframe>
      </div>
    </OverviewSection>
  )
}

export default DomainVideoChannel