import './IncidentItem.scss'
import { useEffect, useState } from "react"
import IncidentMatchResult from './incidentMatchResult/IncidentMatchResult'
import IncidentItemContextMenu from './incidentItemContextMenu/IncidentItemContextMenu'
import IEntityIncident from './../../../../common/interfaces/entityIncident/IEntityIncident'
import IIncidentMatchResult from './../../../../common/interfaces/entityIncident/IIncidentMatchResult'
import IIncidentMatchResultPart from './../../../../common/interfaces/entityIncident/IIncidentMatchResultPart'
import Form from 'react-bootstrap/Form'
import ExtractShortNameFromIncidentFriendlyContainer from '../../../../common/functions/incident/ExtractShortNameFromIncidentFriendlyContainer'
import ToLocaleStringFixed from '../../../../common/functions/dateTime/ConvertToFormat'
import IEntityIncidentsUnderRemoval from '../../../../common/interfaces/entityIncident/IEntityIncidentsUnderRemoval'
import IApplicationSettings from '../../../../common/interfaces/applicationSettings/IApplicationSettings'
import IncidentCollectionRequestType from '../../../../common/types/IncidentCollectionRequestType'

type ComponentProps = {
  customerID:string
  entityIncident:IEntityIncident
  entityIncidentsUnderRemoval:null|undefined|IEntityIncidentsUnderRemoval
  incidentCollectionRequest: IncidentCollectionRequestType
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleIncidentSelectionChange: () => void
  handleRefreshApplication: () => void
  handleEntityIncidentsToRemoveChange:(entityIncidents:IEntityIncident[]) => void
  handleIncidentOverviewRequest: () => void
  applicationSettings: null|undefined|IApplicationSettings
}

const IncidentItem = (props:ComponentProps) => {

  const wordIn:string = 'placeret i'
  const [matchResultVisible, setMatchResultVisible] = useState(false)
  const [matchText, setMatchText] = useState<string|null>(null)
  const [matchTextMoreItems, setMatchTextMoreItems] = useState<string|null>(null)
  const [matchCount, setMatchCount] = useState<number|null>(null)
  const [thisEntityIncidentIsUnderRemoval, setThisEntityIncidentIsUnderRemoval] = useState<boolean>(false)

  const handleClickMatchResult = (event: React.MouseEvent<HTMLElement>) => {
    setMatchResultVisible(previous => !previous)
  }

  const handleSelectionChangeByUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleIncidentSelectionChange()
  }
  
  const entityIncident:IEntityIncident = props.entityIncident
  const entityIncidentID:string = (entityIncident.entity.entityType + '-' + entityIncident.entity.entityID + '-' + entityIncident.incident.name)

  const friendlyIDAdditionElementContent = entityIncident.incident.entityFriendlyID ? 
    <span className='line friendly-id-additional-value'>{entityIncident.incident.entityFriendlyID}</span>:
    undefined     


  const entityTitleTooltip:string = (entityIncident.incident.entityFriendlyContainer) ? 
    entityIncident.incident.entityTitle + ' ' + wordIn.toLowerCase() + ' ' + entityIncident.incident.entityFriendlyContainer:
    entityIncident.incident.entityTitle

  const entityTitleElementContent = (entityIncident.incident.entityAccessLink) ?  
    <a className='entity-title-access-link' href={entityIncident.incident.entityAccessLink} target='_blank' rel='noreferrer'>
        <span className='line entity-title-main-line' title={entityTitleTooltip}>
          {entityIncident.incident.entityTitle == '' ? '[Ingen titel]' : entityIncident.incident.entityTitle}
        </span>
        {friendlyIDAdditionElementContent}
    </a>:
    <>
      <span className='line entity-title-main-line' title={entityTitleTooltip}>
      {entityIncident.incident.entityTitle == '' ? '[Ingen titel]' : entityIncident.incident.entityTitle}
      </span>
      {friendlyIDAdditionElementContent}
    </>

  const entityFriendlyContainerElementContent = (entityIncident.incident.entityFriendlyContainer) 
    ? 
      (entityIncident.incident.entityFriendlyContainerAccessLink) ? 
        <a className='entity-friendly-container-access-link' href={entityIncident.incident.entityFriendlyContainerAccessLink} target='_blank' rel='noreferrer'>
          <span className='line entity-friendly-container-main-line' title={entityIncident.incident.entityFriendlyContainer}>
            {ExtractShortNameFromIncidentFriendlyContainer(entityIncident.incident.entityFriendlyContainer, 60)}
          </span>
        </a>:
        <>
          <span className='line entity-friendly-container-main-line' title={entityIncident.incident.entityFriendlyContainer}>
            {ExtractShortNameFromIncidentFriendlyContainer(entityIncident.incident.entityFriendlyContainer, 60)}
          </span>
        </>
    :
      undefined

  let entityDefaultTimestamp:Date|null = null;
  let entityDefaultTimestampDateLocale:string = ''
  let entityDefaultTimestampDateTimeLocale:string = ''

  if (entityIncident.incident.entityDefaultTimestamp) {
    const dateTimeAreaFormatCode = 'da-DK'  
    entityDefaultTimestamp = new Date(entityIncident.incident.entityDefaultTimestamp)
    entityDefaultTimestampDateLocale = ToLocaleStringFixed(entityDefaultTimestamp, dateTimeAreaFormatCode, false)
    entityDefaultTimestampDateTimeLocale = ToLocaleStringFixed(entityDefaultTimestamp, dateTimeAreaFormatCode, true)
  }

  const entityDefaultTimestampElementContent = (entityIncident.incident.entityDefaultTimestamp) ? 
    <span className='line entity-default-timestamp' title={entityDefaultTimestampDateTimeLocale}>{entityDefaultTimestampDateLocale}</span>:
    undefined     

  useEffect(() => {
   
    if (
      !entityIncident.incident.matchResult ||
      entityIncident.incident.matchResult.length === 0 ||
      !entityIncident.incident.matchResult[0].parts ||
      entityIncident.incident.matchResult[0].parts.length === 0 ||
      !entityIncident.incident.matchResult[0].parts[0].matches ||
      entityIncident.incident.matchResult[0].parts[0].matches.length === 0
    ) return

    let firstMatchNode = entityIncident.incident.matchResult[0].parts[0].matches[0]
    if (!firstMatchNode.match) return

    let matchCountValue:number = 0;  
    entityIncident.incident.matchResult.map((matchResult:IIncidentMatchResult) => (
      matchResult.parts.map((matchResultPart:IIncidentMatchResultPart) => (
        matchCountValue = matchCountValue + matchResultPart.matches.length
      )))) 

    setMatchText(firstMatchNode.match)
    setMatchCount(matchCountValue)
    if (matchCountValue > 1) setMatchTextMoreItems('(+' + matchCountValue + ')')

    if (props.entityIncidentsUnderRemoval?.items.length) {
      setThisEntityIncidentIsUnderRemoval(
        props.entityIncidentsUnderRemoval.items.find(item => item.entityID === entityIncident.entity.entityID) ? 
          true : 
          false
      )
    }

  }, [])

  useEffect(() => {
    if (props.entityIncidentsUnderRemoval?.items.length) {
      setThisEntityIncidentIsUnderRemoval(
        props.entityIncidentsUnderRemoval.items.find(item => item.entityID === entityIncident.entity.entityID) ? 
          true : 
          false
      )
    }
  }, [props.entityIncidentsUnderRemoval?.items])


  return (
    <>
      <div className={'incident-item' + ((thisEntityIncidentIsUnderRemoval) ? ' entity-incident-is-under-removal' : '')}>
        
        <div className='context-menu-wrapper incident-item-cell'>
          <IncidentItemContextMenu 
            key={entityIncidentID} 
            customerID={props.customerID} 
            entityIncident={entityIncident} 
            incidentCollectionRequest={props.incidentCollectionRequest} 
            handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest}
            handleRefreshApplication={props.handleRefreshApplication}
            handleEntityIncidentsToRemoveChange={props.handleEntityIncidentsToRemoveChange}
            handleIncidentOverviewRequest={props.handleIncidentOverviewRequest}
            applicationSettings={props.applicationSettings}
          />
        </div>

        <Form.Check id={'selected-checkbox-' + entityIncidentID} type='checkbox' 
          className='selected-checkbox incident-item-cell' 
          onChange={handleSelectionChangeByUser}/>
        
        <div className='entity-title incident-item-cell text-wrapped'>
          {entityTitleElementContent}
        </div>

        <div className='entity-friendly-container incident-item-cell text-wrapped'>
          {entityFriendlyContainerElementContent}
        </div>
        
        <div className='entity-default-timestamp incident-item-cell text-wrapped'>
          {entityDefaultTimestampElementContent}
        </div>

        <div className='preview-match-result-wrapper incident-item-cell text-wrapped'>
          {
            ((matchCount) && (matchCount > 0)) && 
            <div className='preview-match-result-highlight-frame' onClick={handleClickMatchResult}>
              <div className='preview-match-result text-wrapped'>
                {matchText}
                <div className='preview-match-result-more-items'>
                  {matchTextMoreItems}
                </div>
              </div>
            </div>
          }
        </div>      
      </div>
      {
        (matchCount && matchCount > 0) && 
        <IncidentMatchResult key={entityIncidentID} entityIncidentID={entityIncidentID} visible={matchResultVisible} entityIncident={entityIncident}/>
      }
    </>
  )
}
export default IncidentItem