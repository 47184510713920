import './AutomaticHandlingRecommendations.scss'
import OverviewSection from '../../../common/components/overviewSection/OverviewSection'
import Form from 'react-bootstrap/Form'
import StandardActionButton from '../../../common/components/standardButton/standardActionButton/StandardActionButton'
import { IncidentActionRequestType } from '../../../common/types/IncidentActionRequestType'
import IIncidentOverview from '../../../common/interfaces/incidentOverview/IIncidentOverview'
import IIncidentOverviewRecommendation from '../../../common/interfaces/incidentOverview/IIncidentOverviewRecommendation'
import React, { useEffect, useState } from 'react'
import IncidentCollectionRequestType from '../../../common/types/IncidentCollectionRequestType'
import GetIncidents from '../../../common/services/incident/IncidentService'
import { useMsal } from '@azure/msal-react'
import CreateIncidentAction from '../../../common/services/action/ActionService'
import IEntityIncident from '../../../common/interfaces/entityIncident/IEntityIncident'

type ComponentProps = {
  customerID: string
  domain: string
  incidentOverview:null|undefined|IIncidentOverview
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleIncidentActionChangeRequest: (requestedIncidentAction:IncidentActionRequestType) => void
  handleEntityIncidentsToRemoveChange:(entityIncidents:IEntityIncident[]) => void
}


const AutomaticHandlingRecommendations = (props: ComponentProps) => {

  const { instance } = useMsal()
  
  const handleAction = (id:string, title:string) => {
    const candidateIncidentsRequest:IncidentCollectionRequestType = {Title: 'AutomaticHandlingRecommendationsDelete', Area: 'ClearCommandRequest', Value: {StringValue: 'DeleteDiscarded'}, Timestamp: new Date()}

    GetIncidents(instance, props.customerID, props.domain, candidateIncidentsRequest).then(entityIncidentResult => {
      CreateIncidentAction(instance, props.customerID, 'Delete', entityIncidentResult).then(() => props.handleEntityIncidentsToRemoveChange(entityIncidentResult))
    })
    
  }
  
  const [sumOfSelectedIncidents, setSumOfSelectedIncidents] = useState<number>(0)

  const handleSelectionChange = (event:any) => {
    const incidentCount:number = event.target.value
    setSumOfSelectedIncidents(previousState => event.target.checked ? previousState + +incidentCount : +previousState - +incidentCount)
   }
  
  let incidentSum = 0

  if (props.incidentOverview?.automaticHandling.recommendations !== undefined) {
    const recommendations:IIncidentOverviewRecommendation[] = props.incidentOverview?.automaticHandling.recommendations
    for (const recommendation of (recommendations as IIncidentOverviewRecommendation[])) {
      incidentSum = incidentSum + recommendation.incidentCount
    } 
  }

  return (
    <OverviewSection contextID='automaticHandlingRecommendations' incidentOverview={props.incidentOverview} headerTitle='Din assistent' headerStatus={incidentSum ? (incidentSum + ' stk.') : ''}>
      <div id='automatic-handling-recommendations'>
        <div id='automatic-handling-recommendations-list'>
          {
            props.incidentOverview?.automaticHandling.recommendations.map((recommendation:IIncidentOverviewRecommendation, indexRecommendation:number) => (
              (
                (recommendation.incidentCount > 0) && 
                <React.Fragment key={indexRecommendation}>
                  <Form.Check 
                    id = {'automatic-handling-recommendation-selected-checkbox-' + indexRecommendation} 
                    type = 'switch'
                    className = 'automatic-handling-recommendation-selected-checkbox' 
                    value = {recommendation.incidentCount}
                    label =
                      {
                        recommendation.title + ' (' + recommendation.incidentCount + ' kandidat(er) fundet)'
                      }
                    onChange = {handleSelectionChange} />

{                  (recommendation.incidentCount > 0) && 
                      <div 
                        className='automatic-handling-recommendation-show-incidents'
                        onClick={() => {props.handleIncidentCollectionChangeRequest({Title: recommendation.title.substring(0, 20) + '...', Area: 'ClearCommandRequest', Value: {StringValue: 'DeleteDiscarded'}, Timestamp: new Date()})}}>
                          Vis først
                      </div>
                  }
                </React.Fragment>
              )
            ))

            
          }
        </div>

        {
          (incidentSum === 0) && 
            <div id='automatic-handling-recommendation-no-recommendations'>Ingen forslag til opgaver at udføre, lige nu</div>
        }
        {/* TODO: The dots ... are not perfect. */}
        <div id='automatic-handling-recommendations-action-wrapper'>
          <StandardActionButton status={sumOfSelectedIncidents > 0 ? 'enabled' : 'disabled'} id='execute-actions-button' title={'Gennemfør valgte opgaver for ' + sumOfSelectedIncidents + ' hændelse(r)'} onClick={(id, title) => {handleAction(id, title)}}/>
        </div>

      </div>
    </OverviewSection>
  )

}

export default AutomaticHandlingRecommendations