import axios, { AxiosRequestConfig } from "axios"
import IEntityIncident from '../../interfaces/entityIncident/IEntityIncident'
import { reactMainApiBaseUrl, customerKey, msalMainApiRequest } from "../../../configuration"
import { IPublicClientApplication } from '@azure/msal-browser'
import GetAccessToken from '../../functions/security/AccessTokenProvider'
import IIncidentCancellation from "../../interfaces/incidentCancellation/IIncidentCancellation"


export default async function CreateIncidentCancellation(publicClientApplication:IPublicClientApplication, customerID:string, username:string, entityIncidents: IEntityIncident[], reason: string, reasonRemark: string) {

  const accessToken = await GetAccessToken(publicClientApplication, msalMainApiRequest)
  const parameters = await createRequestParameters()

  let incidentCancellations:IIncidentCancellation[] = entityIncidents.map((entityIncident:IEntityIncident, entityIncidentIndex:number) => {
    return {
      "recordID": entityIncident.record.id,
      "customerID": customerID,
      "userID": username,
      "domain": entityIncident.entity.domain,
      "incidentName": entityIncident.incident.name,
      "reason": reason,
      "reasonRemark": reasonRemark,
      "timestamp": new Date()
    } as IIncidentCancellation;
  });

  const requestConfiguration:AxiosRequestConfig = {
    url: (reactMainApiBaseUrl(customerID) + '/incident-cancellations'),
    method: 'POST',
    params: parameters,
    headers: { Authorization: ('Bearer ' + accessToken)},
    data: incidentCancellations
  }
  
  return axios(requestConfiguration)
  .then(result => result.data)
  .catch(event => Error("Could not send the incident cancellations.\n\n" + event))
}

const createRequestParameters = async () => {

  const parameters = new URLSearchParams()

  parameters.append('code', customerKey)

  return parameters
}