import GetIncidents from './../../../common/services/incident/IncidentService'
import React, { useState, useEffect, useRef } from 'react'
import IncidentTypeGroup from './incidentTypeGroup/IncidentTypeGroup'
import IncidentItem from './incidentItem/IncidentItem'
import { Accordion } from 'react-bootstrap'
import IEntityIncident from './../../../common/interfaces/entityIncident/IEntityIncident'
import { IncidentCollectionRequestType } from './../../../common/types/IncidentCollectionRequestType'
import { useMsal } from '@azure/msal-react'
import EntityIncidentGroupType from '../../../common/types/EntityIncidentGroupType'
import './IncidentList.scss'
import IncidentCollectionSortDetailsType from '../../../common/types/IncidentCollectionSortType'
import IEntityIncidentsUnderRemoval from '../../../common/interfaces/entityIncident/IEntityIncidentsUnderRemoval'
import IApplicationSettings from '../../../common/interfaces/applicationSettings/IApplicationSettings'


type ComponentProps = {
  customerID: string
  domain:string
  incidentCollectionRequest: IncidentCollectionRequestType
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleIncidentCollectionChange: (incidentCollection:IEntityIncident[]) => void
  incidentSelection?: IEntityIncident[]
  handleIncidentSelectionChange: () => void
  handleEntityIncidentsToRemoveChange:(entityIncidents:IEntityIncident[]) => void
  entityIncidentsUnderRemoval:null|undefined|IEntityIncidentsUnderRemoval
  incidentCollectionSortDetails: IncidentCollectionSortDetailsType
  handleRefreshApplication: () => void
  handleIncidentCollectionSortDetailsChange: (sortDetails:IncidentCollectionSortDetailsType) => void
  handleIncidentOverviewRequest: () => void
  applicationSettings: null|undefined|IApplicationSettings
}


const compareFields = (sortAscendingValueComparison:number, firstField:any, secondField:any):number => {
  if (firstField > secondField) return (1 * sortAscendingValueComparison)
  if (firstField < secondField) return (-1 * sortAscendingValueComparison)
  return 0
}

const applySort = (sortDetails:IncidentCollectionSortDetailsType, entityIncidents:IEntityIncident[]):IEntityIncident[] => {

  var sortAscendingValueComparison:number = sortDetails.sortAcsending[sortDetails.activeIndex] ? 1 : -1
  
  return entityIncidents.sort((first, second) =>
    {
      switch(sortDetails.activeIndex){   
        case 0: return compareFields(sortAscendingValueComparison, first.incident.entityTitle.toUpperCase(), second.incident.entityTitle.toUpperCase())
        case 1: return compareFields(sortAscendingValueComparison, first.incident.entityFriendlyContainer?.toUpperCase(), second.incident.entityFriendlyContainer?.toUpperCase())
        case 2: return compareFields(sortAscendingValueComparison, first.incident.entityDefaultTimestamp, second.incident.entityDefaultTimestamp)
        default: return 0;
      }
    }
  )
}

const IncidentList = (props:ComponentProps) => {

  const {instance} = useMsal()
  const [entityIncidents, setEntityIncidents] = useState<IEntityIncident[]>([])
  const [groupedEntityIncidents, setGroupedEntityIncidents] = useState<EntityIncidentGroupType[]>([])


  useEffect(() => {
    GetIncidents(instance, props.customerID, props.domain, props.incidentCollectionRequest).then(entityIncidentResult => setEntityIncidents(entityIncidentResult))
  }, [props.incidentCollectionRequest.Timestamp])

  useEffect(() => {

    var entityIncidentResult:IEntityIncident[] = 
      (
        props.incidentCollectionSortDetails 
          ? 
            applySort(props.incidentCollectionSortDetails, entityIncidents) 
          : 
          entityIncidents
      )
      
    const groupedEntityIncidentGroupResult:EntityIncidentGroupType[] = []
    for (const entityIncident of entityIncidentResult) {
      const foundEntityIncidentGroup:EntityIncidentGroupType|undefined = groupedEntityIncidentGroupResult.find(item => item.Name === entityIncident.incident.name)
      if (foundEntityIncidentGroup === undefined)
        groupedEntityIncidentGroupResult.push({Name: entityIncident.incident.name, EntityIncidents: [entityIncident]})
      else
        foundEntityIncidentGroup.EntityIncidents.push(entityIncident)
    }  
    setGroupedEntityIncidents(groupedEntityIncidentGroupResult)
    props.handleIncidentCollectionChange(entityIncidentResult)
    props.handleIncidentSelectionChange()
  }, [entityIncidents, props.incidentCollectionSortDetails])

  const renderEmpty = () => {
    return (
      <div id='incident-list'>
      </div>
    )
  }

  const renderSingularGroup = (groupName:string) => {
    return (
      <div id='incident-list'>
        <Accordion id='incident-list-accordion' activeKey={groupName}>
          { renderSubComponents() }
        </Accordion>
      </div>
    )
  }

  const renderMultipleGroups = () => {
    return (
      <div id='incident-list'>
        <Accordion id='incident-list-accordion'>
          { renderSubComponents() }
        </Accordion>
      </div>
    )
  }
  
    
  const renderSubComponents = () => {
    return (
      <>
      {
        groupedEntityIncidents.map((entityIncidentGroup:EntityIncidentGroupType) => {
          if (entityIncidentGroup.EntityIncidents.length > 0)
          {
            const groupSeverityLevel:number = entityIncidentGroup.EntityIncidents[0].incident.severityLevel
            return (
              <Accordion.Item key={entityIncidentGroup.Name} eventKey={entityIncidentGroup.Name}>
                <Accordion.Header>
                  <IncidentTypeGroup 
                    key={entityIncidentGroup.Name} 
                    {...{
                      'name': entityIncidentGroup.Name, 
                      'severityLevel': groupSeverityLevel, 
                      'entityIncidents': entityIncidentGroup.EntityIncidents, 
                      'incidentSelection': props.incidentSelection, 
                      'handleIncidentSelectionChange': props.handleIncidentSelectionChange
                    }} />
                </Accordion.Header>
                <Accordion.Body>
                  {
                    entityIncidentGroup.EntityIncidents.map((entityIncident:IEntityIncident) => (
                      (
                        <IncidentItem 
                          key=
                            {
                              entityIncident.entity.entityType + '-' + 
                              entityIncident.entity.entityID + '-' + 
                              entityIncident.incident.name
                            } 
                          customerID={props.customerID} 
                          entityIncident={entityIncident} 
                          incidentCollectionRequest={props.incidentCollectionRequest} 
                          handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest}
                          entityIncidentsUnderRemoval={props.entityIncidentsUnderRemoval}
                          handleIncidentSelectionChange={props.handleIncidentSelectionChange}
                          handleRefreshApplication={props.handleRefreshApplication} 
                          handleEntityIncidentsToRemoveChange={props.handleEntityIncidentsToRemoveChange}
                          handleIncidentOverviewRequest={props.handleIncidentOverviewRequest}
                          applicationSettings={props.applicationSettings}
                          />
                      )
                    ))
                  }
                </Accordion.Body>
              </Accordion.Item>
            )
          }
        })
      }
      </>
    )
  }

  switch(groupedEntityIncidents.length) {
    case 0:   { return renderEmpty() } 
    case 1:   { return renderSingularGroup(groupedEntityIncidents[0].Name) }
    default:  { return renderMultipleGroups() }
  }

}

export default IncidentList