import './incidentListContextMenu.scss'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import contextMenuImage from './../../../../../common/images/context-menu/contextMenuLight.png'
import { useMsal } from '@azure/msal-react'
import IEntityIncident from './../../../../../common/interfaces/entityIncident/IEntityIncident'
import CreateIncidentAction from '../../../../../common/services/action/ActionService'
import { useState } from 'react'
import IncidentCancellationContextMenuPart from '../../../../../common/components/incidentCancellation/IncidentCancellationContextMenuPart'
import IApplicationSettings from '../../../../../common/interfaces/applicationSettings/IApplicationSettings'
import IncidentCollectionRequestType from '../../../../../common/types/IncidentCollectionRequestType'
import CreateIncidentCancellation from '../../../../../common/services/incidentCancellation/IncidentCancellationService'

type ComponentProps = {
  customerID:string
  entityIncidents: IEntityIncident[]
  incidentCollectionRequest: IncidentCollectionRequestType
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleRefreshApplication: () => void
  handleEntityIncidentsToRemoveChange:(entityIncidents:IEntityIncident[]) => void
  handleIncidentOverviewRequest: () => void
  applicationSettings: null|undefined|IApplicationSettings
}

const IncidentListContextMenu = (props:ComponentProps) => {

  const { instance } = useMsal()

  const [incidentCancellationVisible, setIncidentCancellationVisible] = useState<boolean>(false)

  let user = instance.getActiveAccount()

  const renderContextMenu:any = (entityIncidents:IEntityIncident[]) => (
    <Popover id='incident-list-context-menu-popover'>
      <Popover.Header className='incident-list-context-menu-popover-header'>handlinger for de valgte emner</Popover.Header>
      <Popover.Body className='incident-list-context-menu-popover-body'>
        {
          props.entityIncidents && 
            <div className='incident-list-context-menu-popover-body-menu-item' onClick={handleCreateActionDelete}>Slet {props.entityIncidents.length} stk. permanent</div>
        }

        <div className='incident-item-context-menu-popover-body-menu-item' onClick={() => {setIncidentCancellationVisible((previous) => !previous)}}>Annullering af {props.entityIncidents.length} hændelse(r)...</div>
        <div className='incident-cancellation-context-menu-part-container'>
          {              
            incidentCancellationVisible && 
            <IncidentCancellationContextMenuPart 
              customerID={props.customerID} 
              entityIncidents={entityIncidents} 
              applicationSettings={props.applicationSettings} 
              handleIncidentCancellation={handleIncidentCancellation} />
          }
        </div>

      </Popover.Body>
    </Popover>
  )

  const handleCreateActionDelete = (event: React.MouseEvent<HTMLElement>) => {
    if (props.entityIncidents) {
      CreateIncidentAction(instance, props.customerID, 'Delete', props.entityIncidents)
      props.handleEntityIncidentsToRemoveChange(props.entityIncidents)
      
    }
    hidePopover('incident-list-context-menu-popover')
  }

  const handleIncidentCancellation = (reason:string, reasonRemark:string) => {
    if (props.entityIncidents) 
    {
      if (user)
      { 
        CreateIncidentCancellation(instance, props.customerID, user.username, props.entityIncidents, reason, reasonRemark)
        .then(() => {
          props.handleIncidentOverviewRequest()
          props.handleIncidentCollectionChangeRequest(
          {
            Title: props.incidentCollectionRequest.Title,
            Area: props.incidentCollectionRequest.Area, 
            Value: props.incidentCollectionRequest.Value, 
            Timestamp: new Date()
          })
        })
      }
    }
    hidePopover('incident-list-context-menu-popover')
  }

  const hidePopover = (popoverID: string) => {
    const popover = document.getElementById(popoverID)
    popover?.style.setProperty('display', 'none')
  }


  return (
    <> 
      <OverlayTrigger trigger={['click']} rootClose={true} placement='bottom' overlay={renderContextMenu(props.entityIncidents) }>
        {
          props.entityIncidents && props.entityIncidents.length > 0 ? 
            <div className='incident-list-context-menu'>
              <img className='incident-list-context-menu-image highlight-context-menu' alt='' src={contextMenuImage}></img>
            </div>
          : 
            <div></div>
        }        
      </OverlayTrigger>
    </>
  )
}

export default IncidentListContextMenu
