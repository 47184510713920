import './ManualHandlingTextSearch.scss'
import OverviewSection from '../../../../common/components/overviewSection/OverviewSection'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import { IncidentCollectionRequestType } from '../../../../common/types/IncidentCollectionRequestType'
import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import IIncidentOverview from '../../../../common/interfaces/incidentOverview/IIncidentOverview'

type ComponentProps = {
  incidentOverview:null|undefined|IIncidentOverview
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  incidentCollectionCloseRequest: string
}


const ManualHandlingTextSearch = (props:ComponentProps) => {

  const [searchPhrase, setSearchPhrase] = useState<string>('')


  useEffect(() => {
    setSearchPhrase('')
  }, [props.incidentCollectionCloseRequest])

  const simpleSearchHeaderElement = 
    <input
      type='form-control'
      id='manual-handling-text-search-input-simple'
      className='manual-handling-text-search-input'
      spellCheck='false'
      placeholder='Indtast søgeord'
      value={searchPhrase} 
      onClick={(event) => event.stopPropagation()}
      onChange={(event) => setSearchPhrase(event.target.value)}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          props.handleIncidentCollectionChangeRequest({Title: 'Fritekstsøgning', Area: 'FreeTextRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})
        }
      }}
  />
  
  return (
    <OverviewSection contextID='manualHandlingTextSearch' incidentOverview={props.incidentOverview} headerElement={simpleSearchHeaderElement} headerTitle='Søgning'>
      <div id='manual-handling-text-search'>
        <input
          id='manual-handling-text-search-input-normal' 
          className='manual-handling-text-search-input'
          type='form-control'
          spellCheck='false'
          placeholder='Indtast søgeord' 
          value={searchPhrase} 
          onChange={(event) => setSearchPhrase(event.target.value)}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              props.handleIncidentCollectionChangeRequest({Title: 'Fritekstsøgning', Area: 'FreeTextRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})
            }
          }}
        />
        <div id='manual-handling-text-search-action'>

          <Dropdown as={ButtonGroup}>
            <Button id="manual-handling-text-search-action-button" variant="success" size='sm'
              onClick={() => {
                props.handleIncidentCollectionChangeRequest({Title: 'Fritekstsøgning', Area: 'FreeTextRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})
              }}
            >Søg</Button>

            <Dropdown.Toggle split variant="success" id="dropdown-split-basic"  size='sm'/>

            <Dropdown.Menu>
              <Dropdown.Item key='search-area-name' id='search-area-name' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Hændelsestype', Area: 'NameRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})}}>Hændelsestype</Dropdown.Item>
              <Dropdown.Item key='search-area-entityType' id='search-area-entity-type' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Emnetype', Area: 'EntityTypeRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})}}>Emnetype</Dropdown.Item>
              <Dropdown.Item key='search-area-entity-title' id='search-area-entity-title' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Emnetitel', Area: 'EntityTitleRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})}}>Emnetitel</Dropdown.Item>
              <Dropdown.Item key='search-area-entity-friendly-id' id='search-area-entity-friendly-id' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Emne-ID', Area: 'EntityIDRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})}}>Emne-ID</Dropdown.Item>
              <Dropdown.Item key='search-area-entity-friendly-wrapper' id='search-area-entity-friendly-wrapper' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Placering', Area: 'EntityFriendlyContainerRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})}}>Placering</Dropdown.Item>
              <Dropdown.Item key='search-area-entity-default-timestamp' id='search-area-entity-default-timestamp' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Emnedato', Area: 'EntityDefaultTimestampRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})}}>Emnedato</Dropdown.Item>
              <Dropdown.Item key='search-area-match-resultMatch' id='search-area-match-result-match' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Match', Area: 'MatchResultMatchRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})}}>Match</Dropdown.Item>
              <Dropdown.Item key='search-area-matchresultAssessment' id='search-area-match-result-assessment' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Match-vurdering', Area: 'MatchResultAssessmentRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})}}>Match-vurdering</Dropdown.Item>
              <Dropdown.Item key='search-area-observationtimestamp' id='search-area-observation-timestamp' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Observationsdato', Area: 'ObservationTimestampRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})}}>Observationsdato</Dropdown.Item>
              <Dropdown.Item key='search-area-severity-level' id='search-area-severity-level' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Alvorlighedsniveau', Area: 'SeverityLevelRequest', Value: {NumberValue: Number(searchPhrase)}, Timestamp: new Date()})}}>Alvorlighedsniveau</Dropdown.Item>
              <Dropdown.Item key='search-area-workload' id='search-area-workload' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Arbejdsindsats', Area: 'WorkloadRequest', Value: {NumberValue: Number(searchPhrase)}, Timestamp: new Date()})}}>Arbejdsindsats</Dropdown.Item>
              <Dropdown.Item key='search-area-free-text' id='search-area-free-text' onClick={() => {props.handleIncidentCollectionChangeRequest({Title: 'Fritekstsøgning', Area: 'FreeTextRequest', Value: {StringValue: searchPhrase}, Timestamp: new Date()})}}>Fritekstsøgning</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </OverviewSection>
  )
}

export default ManualHandlingTextSearch